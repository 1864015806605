import React from 'react'

import type { Moment } from 'moment'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import { getColorLookupBy } from 'features/solutionsTimeline/placement-colors'
import type { PlacementBlockData } from 'features/solutionsTimeline/timelines/PlacementDetailsTimeline'
import { PlacementDetailsTimeline } from 'features/solutionsTimeline/timelines/PlacementDetailsTimeline'
import { getRegularOrSinglePlacementsFromSolution } from 'features/visiting/types'
import { VisitingTimeline } from 'features/visiting/VisitingTimeline'
import * as routes from 'routes'
import { formatLink } from 'utils/links'

import { useSolutionContext } from './contexts/solution'

interface Props {
  focus: Moment
  setFocus: (date: Moment) => void
}

const StyledAccountSolutionSummary = styled.div`
  width: 100%;
`

const StyledSectionHeading = styled.div`
  position: relative;
  left: -140px;
  height: 1px;
`

const StyledPlacementSlots = styled.div`
  margin-top: 20px;
`

const StyledTimelineWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`

const StyledTimelineText = styled.div`
  background-color: #ffffff;
  color: #73819c;
  margin-bottom: 8px;
  margin-left: 10px;
`

const StyledLinkContainer = styled.span`
  a {
    display: block;
    width: 100%;
    height: 100%;
    color: ${({ textColor }) => textColor};
    &:hover {
      text-decoration: none;
    }
  }
`

const getBlockContentText = ({ data }: TimelineBlock<PlacementBlockData>) => {
  if (data && data.type === 'SUSPENDED') {
    return ''
  }
  if (data && data.professionalName) {
    return data.professionalName
  }
  return '(unassigned)'
}

export const AccountSolutionSummary = ({ focus, setFocus }: Props) => {
  const { solution } = useSolutionContext()

  const visitingPlacements = getRegularOrSinglePlacementsFromSolution(
    solution.placementSlots[0],
  )

  return (
    <StyledAccountSolutionSummary>
      <StyledPlacementSlots>
        <StyledSectionHeading>Placement details</StyledSectionHeading>
        {solution.placementSlots
          ? solution.placementSlots.map((slot) => (
              /* eslint-disable react/jsx-indent */
              <div key={slot.slotId}>
                <StyledTimelineWrapper>
                  <StyledTimelineText>
                    {slot.description} - {solution.tariffDescription}
                  </StyledTimelineText>
                </StyledTimelineWrapper>
                {visitingPlacements.length > 0 ? (
                  <VisitingTimeline
                    visitingPlacements={visitingPlacements}
                    focus={focus}
                    daysDisplayed={28}
                    setFocus={setFocus}
                  />
                ) : (
                  <PlacementDetailsTimeline
                    daysDisplayed={28}
                    focus={focus}
                    setFocus={setFocus}
                    renderBlock={RenderBlockContent}
                    placements={slot.placements}
                    colorLookup={getColorLookupBy(
                      solution.placementSlots.flatMap(
                        (slot) => slot.placements,
                      ),
                      'professionalId',
                    )}
                  />
                )}
              </div>
            ))
          : /* esline-enable react/jsx-indent */
            null}
      </StyledPlacementSlots>
    </StyledAccountSolutionSummary>
  )
}

interface BlockContentProps {
  block: TimelineBlock<PlacementBlockData>
  accountId: string
}

const BlockContent = ({ block, accountId }: BlockContentProps) => {
  const text = getBlockContentText(block)
  const url = `${formatLink(routes.solutions, {
    accountId,
  })}?solutionId=${block.data?.solutionId}&placementId=${block.data?.id}`
  return (
    <StyledLinkContainer textColor={block.colors.text}>
      <Link to={url}>{text}</Link>
    </StyledLinkContainer>
  )
}

interface RenderPlacementTimelineBlockProps {
  block: TimelineBlock<PlacementBlockData>
}

const RenderBlockContent = ({ block }: RenderPlacementTimelineBlockProps) => {
  const { accountId } = useParams<{ accountId: string }>()
  return <BlockContent block={block} accountId={accountId} />
}
